import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import StudentLogin from "@/views/StudentLogin.vue";
import ls from 'local-storage'
import user from '../domain/User'
import TeacherLogin from "@/views/TeacherLogin";
import Play from "@/views/Play";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/student',
    name: 'StudentLogin',
    component: StudentLogin,
  },
  {
    path: '/teacher',
    name: 'TeacherLogin',
    component: TeacherLogin,
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const code = to.query.code
  if (code) {
    ls.set('wx-login-code', code)
  }
  const isAuthenticated = user.isAuthenticated
  console.log('The user is ' + (isAuthenticated ? '' : 'not ') + 'authenticated')
  // next()
  if (to.name === 'TeacherLogin') next()
  else if (to.name !== 'StudentLogin' && !isAuthenticated) next({name: 'StudentLogin'})
  else next()
})

export default router
