<template>
  <div class="header">
    <router-link class="app-title" to="/">
      <img src="../assets/app-title-white.png" width="214">
    </router-link>
    <div class="notice">系统公告：欢迎您使用写字达标在线教学系统！</div>
    <div class="user">
      <img src="../assets/app-icon.png" width="28">
      <div class="user-name">{{ name }}</div>
      <div class="divider"></div>
      <div class="sign-out-button" @click="signOut">退出</div>
    </div>
  </div>
</template>

<script>
import user from "@/domain/User";

export default {
  name: 'Navigator',
  data() {
    return {
      name: user.nickname || '教师',
    }
  },
  methods: {
    signOut() {
      user.signOut()
      window.location.reload()
    },
  },
}
</script>

<style scoped src="../style/header.css"/>