<template>
  <div :class="'body ' + (isTeacher ? 'teacher' : '')">
    <navigator/>
    <div class="navi">
      <img class="navi-go-back" src="../assets/icon-back.png" @click="goBack">
      <div class="navi-title">
        <span class="navi-secondary">{{ type == 'video' ? '辅导视频' : '教学课件' }}</span>
        <span v-if="type == 'video'"><span>-</span>{{ lessonName }}</span>
      </div>
    </div>
    <div v-if="isLoaded">
      <div v-if="type == 'video'" class="menu">
        <div class="words">
          <div v-for="(word,index) in words" :key="index" :class="'word ' + (currentIndex == index ? 'active' : '')" @click="onClickWord(index)">
            <img class="word-cover" :src="word.cover" width="32" height="32">
            <img class="word-pointer" src="../assets/icon-word-pointer.png">
          </div>
        </div>
        <div class="operation">
          <div :class="'menu-button ' + (videoType == 0 ? 'active' : '')" @click="videoType = 0">书写指导</div>
          <div :class="'menu-button ' + (videoType == 1 ? 'active' : '')" @click="videoType = 1">笔顺视频</div>
        </div>
      </div>
      <div class="player">
        <iframe v-if="type == 'pdf'" :src="viewerJsSrc"
                width='700' height='460' allowfullscreen webkitallowfullscreen></iframe>
        <video v-if="type == 'video' && videoType == 0" :src="words[currentIndex]['videopath_soft1']" controls/>
        <video v-if="type == 'video' && videoType == 1" :src="words[currentIndex]['videopath_hard']" controls/>
      </div>
    </div>
    <loader-stroke v-else :margin-top="20"/>
  </div>
</template>

<script>
// import Header from "@/components/Header";

import Navigator from "@/components/Navigator";
import router from "@/router";
import user from "@/domain/User";
import {requestLesson} from "@/domain/Lesson";
import LoaderStroke from "@/components/LoaderStroke";

export default {
  name: 'Play',
  components: {
    LoaderStroke,
    Navigator
    // Header,
  },
  data() {
    return {
      isLoaded: false,
      origin: '',
      type: '',
      address: '',
      lessonId: 0,
      lessonName: '',
      words: [],
      wordId: 0,
      videoType: 0,
      currentIndex: 0,
      isTeacher: user.isTeacher,
    }
  },
  computed: {
    viewerJsSrc() {
      console.log(this.origin + '/ViewerJS/#../' + this.address)
      return this.origin + '/ViewerJS/#../' + 'pdf/shuimofeng.pdf' //this.address
    },
  },
  created() {
  },
  mounted() {
  },
  // Called when a kept-alive component is activated.
  activated() {
    this.origin = window.location.origin
    this.type = this.$route.query.type// || 'pdf'
    this.address = this.$route.query.address

    this.lessonId = this.$route.query.lessonId
    this.lessonName = this.$route.query.lessonName
    this.wordId = this.$route.query.wordId
    if (this.type == 'video' && this.lessonId) {
      requestLesson(this.lessonId).then(data => {
        this.words = data.words
        this.currentIndex = (this.words || []).findIndex(e => e.wid == this.wordId)
        this.isLoaded = true
      })
    }
    if (this.type == 'pdf') {
      this.isLoaded = true
    }
  },
  methods: {
    goBack() {
      router.back()
    },
    onClickWord(index) {
      this.currentIndex = index
    }
  },
}
</script>

<style scoped>
.body {
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 30px;
  background-image: url("../assets/main-bkg-student.png");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.body.teacher {
  background-image: url("../assets/main-bkg-teacher.png");
}

.navi {
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  font-size: 11px;
  color: #333333;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 20px 0;
  padding: 0 15px;
}

.navi-title {
  margin-left: 12px;
}

.navi-title span {
  margin-right: 3px;
}

.navi-secondary {
  color: #999999;
}

.navi-go-back {
  cursor: pointer;
  margin: 7px 0;
  width: 20px;
  height: 20px;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 20px 0;
}

.words {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.word {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word + .word {
  margin-left: 5px;
}

.word .word-cover {
  width: 32px;
  height: 32px;
}

.word.active .word-cover {
  width: 40px;
  height: 40px;
}

.word .word-pointer {
  width: 8px;
  height: 12px;
  margin-top: 5px;
  opacity: 0;
}

.word.active .word-pointer {
  opacity: 1;
}

.menu .operation {
  display: flex;
  margin-top: 5px;
}

.menu-button {
  width: 70px;
  height: 28px;
  line-height: 28px;
  background: #FFFFFF;
  color: #999999;
  border: 1px solid #999999;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.menu-button + .menu-button {
  margin-left: 3px;
}

.menu-button.active {
  background: #D64F54;
  color: #F3F5F8;
  border-color: #D64F54;
}

.player {
  margin: 30px 0 0;
}

video {
  height: 315px;
  border-radius: 5px;
}
</style>