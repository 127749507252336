<template>
  <div>
    <div class="loader-stroke" :style="'margin-top:'+marginTop+'px;'+`width:${size}px;height:${size}px`"></div>
  </div>
</template>

<script>
export default {
  name: "LoaderStroke",
  props: {
    marginTop: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 50,
    },
  },
}
</script>

<style>
.loader-stroke {
  width: 50px;
  height: 50px;
  background-image: url("../assets/loader-brush.png");
  background-size: cover;
  background-position: center;
  margin: auto;

  animation-duration: 1.2s;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.2, 0.7, 0.9, 0.2);
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>