<template>
  <div class="main">
    <img class="header" src="../assets/app-title.png">
    <div class="form">
      <loader-stroke v-if="!isLoaded" :margin-top="20"/>
      <wxlogin
          v-if="isLoaded"
          appid="wxeecf9dd165c0db82"
          scope="snsapi_login"
          redirect_uri="https%3A%2F%2Fxiezidabiao.com"
          state="abc"
          :href="wxExtraStylePath"
      />
    </div>
    <div class="footer-note">
<!--      <router-link to="/teacher-login">教师版</router-link>-->
<!--      |-->
      <a href="http://beian.miit.gov.cn/">京ICP备19008225号-3</a>
    </div>
  </div>
</template>

<script>
import wxlogin from 'vue-wxlogin'
import ls from 'local-storage'
import LoaderStroke from "@/components/LoaderStroke";
import user from "@/domain/User";
import {remote} from "@/utils/remote";
import router from "@/router";

function requestLoginUser(code) {
  return remote
      .post('StudentLogin', {
        code,
      })
      .then(({data}) => {
        console.log(data)
        if (data.flag) {
          return data.data
        }
      })
      .catch(function (error) {
        console.log(error);
      });
}

export default {
  name: 'StudentLogin',
  components: {
    LoaderStroke,
    wxlogin
  },
  data() {
    return {
      wxExtraStylePath: '',
      isLoaded: false,
      code: '',
    }
  },
  created() {
    if (user.isAuthenticated) router.replace({name: 'Home'})
    this.wxExtraStylePath = window.location.origin + '/wx-login-style.css'
    this.code = ls('wx-login-code')
    if (this.code) {
      ls.remove('wx-login-code')
      requestLoginUser(this.code).then(data => {
        user.token = data["token"]
        user.id = data["user"].id
        user.nickname = data["user"].nickname
        user.avatar = data["user"].avatar
        user.save()
        router.replace({name: 'Home'})
      })
    } else {
      this.isLoaded = true
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.main {
  min-height: 100vh;
  background-image: url("../assets/login-bkg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.header {
  margin-top: 75px;
  height: 32px;
}

.form {
  box-sizing: border-box;
  margin-top: 26px;
  padding-top: 20px;
  background-image: url("../assets/login-form-bkg-student.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 382px;
}

.footer-note {
  font-size: 11px;
  font-weight: 400;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.footer-note a {
  color: #999999;
  text-decoration: none;
}
</style>