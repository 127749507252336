import ls from 'local-storage'
import {remote} from "@/utils/remote";

class User {
  id = 0
  token = ''
  avatar = ''
  nickname = ''
  isTeacher = false

  get isAuthenticated() {
    return this.id && this.token
  }

  constructor() {
  }

  recover() {
    const saved = ls.get('user')
    console.log('saved user: ', saved)
    if (saved) {
      Object.keys(saved).forEach(key => {
        this[key] = saved[key]
      })
    }
  }

  save() {
    ls.set('user', user)
  }

  signOut() {
    ls.remove('user')
  }
}

const user = new Proxy(new User, {
  set(target, p, value, receiver) {
    target[p] = value
    if (p == 'token') {
      remote.defaults.headers.common['Token'] = value;
    }
    if (p == 'id') {
      remote.defaults.headers.common['Loginid'] = value;
    }
    return true
  }
})

user.recover()

if (process.env.NODE_ENV == 'development') {
  // user.id = 1077
  // user.token = '1631869761267613001077'
  // user.nickname = '莘'
  // user.isTeacher = false
}

export default user