<template>
  <div class="catalog">
    <div v-for="(item, index) in catalogForRender" :key="index"
         :class="'catalog-block ' + (item.type == 'branch' ? (item.isColorBlue ? 'title blue ' : 'title orange ') : '') + (currentLessonId == item['lid'] ? 'active ' : '')"
         @click="onClickCatalogItem(item['lid'])">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Catalog',
  props: {
    catalog: Array,
    lessons: Array,
  },
  data() {
    return {
      currentLessonId: 0,
      colorSeedNumber: 0,
    }
  },
  watch: {
    lessons() {
      if (this.lessons.length)
        this.currentLessonId = this.lessons[0]['lid']
    },
  },
  computed: {
    catalogForRender() {
      return this.catalog.map(item => {
        if (item.type == 'branch') item.isColorBlue = this.getColorSeedNumber() % 2 == 0
        return item
      })
    },
  },
  created() {
    if (this.lessons.length)
      this.currentLessonId = this.lessons[0]['lid']
  },
  methods: {
    getColorSeedNumber() {
      return this.colorSeedNumber++
    },
    onClickCatalogItem(lessonId) {
      if (lessonId) {
        this.currentLessonId = lessonId
        const index = this.lessons.findIndex(item => item.lid == lessonId)
        this.$emit('onSelectLesson', index)
      }
    }
  },
}
</script>

<style scoped>
.catalog {
  margin-left: -5px;
  display: flex;
  flex-wrap: wrap;
}

.catalog-block {
  margin-top: 15px;
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 13px;
  background: #FFFFFF;
  color: #666666;
  flex-shrink: 0;
  cursor: pointer;
}

.catalog-block.title {
  cursor: default;
}

.catalog-block.blue {
  background: #E0F0F6;
  color: #00AEFF;
}

.catalog-block.orange {
  background: #F7EAD2;
  color: #FF8400;
}

.catalog-block.active {
  cursor: default;
  background: #D64F54;
  color: #FFFFFF;
}
</style>