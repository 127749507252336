import {typicalRequest} from "@/utils/request";
import {SERVER_URL} from "@/utils/remote";

let lessonId
let lesson

export function requestLesson(id) {
  if (id == lessonId) return Promise.resolve(Object.assign({}, lesson))
  lessonId = id
  return typicalRequest('GetClassContent', {
    data: {lid: id}
  }).then(data => {
    lesson = {
      courseware: data['kejian'],
      words: data.list.map(word => {
        word.cover = SERVER_URL + word['default1']
        return word
      })
    }
    return Object.assign({}, lesson)
  })
}
