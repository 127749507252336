<template>
  <div class="main">
    <img class="header" src="../assets/app-title.png">
    <div class="form">
      <!--      <loader-stroke v-if="!isLoaded" :margin-top="20"/>-->
      <div class="form-wrapper">
        <div class="form-header">教师端</div>
        <div>
          <div class="form-title">用户账号</div>
          <div class="form-input">
            <img class="form-icon" src="../assets/icon-form-account.png"/>
            <div class="input-divider"></div>
            <input v-model="name" placeholder="请输入账号"/>
          </div>
        </div>
        <div>
          <div class="form-title">登陆密码</div>
          <div class="form-input">
            <img class="form-icon" src="../assets/icon-form-password.png"/>
            <div class="input-divider"></div>
            <input v-model="password" type="password" placeholder="请输入密码"/>
          </div>
        </div>
        <div class="remember-password" @click="toggleRememberPassword">
          <img v-if="rememberPassword" src="../assets/icon-checkbox-checked.png"
               width="12" height="9"/>
          <img v-if="!rememberPassword" src="../assets/icon-checkbox-unchecked.png"
               width="12" height="9"/>
          记住密码
        </div>
        <loader-stroke v-if="isLoading" :margin-top="36" :size="45"/>
        <div v-if="!isLoading" class="login-button" @click="onClickLogin">立即登陆</div>
      </div>
    </div>
    <div>
      <a class="footer-note" href="http://beian.miit.gov.cn/">京ICP备19008225号-3</a>
    </div>
  </div>
</template>

<script>
import ls from 'local-storage'
import LoaderStroke from "@/components/LoaderStroke";
import {remote} from "@/utils/remote";
import user from "@/domain/User";
import router from "@/router";

function requestLoginTeacher(username, password) {
  return new Promise((resolve, reject) => {
    return remote
        .post('TeacherLogin', {
          username,
          password
        })
        .then(({data}) => {
          console.log(data)
          if (data.data.status == 2) reject('mismatch')
          else if (data.flag && data.data.user.status == 1) {
            resolve(data.data)
          } else {
            reject(data.data['msg'])
          }
        })
        .catch(function (error) {
          console.log(error);
        })
  })
}

export default {
  name: 'TeacherLogin',
  components: {
    LoaderStroke,
  },
  data() {
    return {
      isLoading: false,
      name: '',
      password: '',
      rememberPassword: !false,
    }
  },
  created() {
    if (ls.get('remembered-user-name')) this.name = ls.get('remembered-user-name')
    if (ls.get('remembered-user-password')) this.password = ls.get('remembered-user-password')

    // if (user.isAuthenticated) router.replace({name: 'Home'})

    // if (true) {
    //   user.id = 1
    //   user.token = 'abc'
    //   setTimeout(() => {
    //     // router.replace({name: 'About'})
    //   }, 2000)
    // }
    // setTimeout(() => {
    //   this.isLoaded = true
    // }, 5000)
  },
  mounted() {
  },
  methods: {
    toggleRememberPassword() {
      this.rememberPassword = !this.rememberPassword
    },
    onClickLogin() {
      if (this.name.length > 0 && this.password.length > 0) {
        this.isLoading = true
        console.log(this.name)
        console.log(this.password)
        requestLoginTeacher(this.name, this.password).then(data => {
          if (this.rememberPassword) {
            ls.set('remembered-user-name', this.name)
            ls.set('remembered-user-password', this.password)
          } else {
            ls.remove('remembered-user-name')
            ls.remove('remembered-user-password')
          }
          user.isTeacher = true
          user.id = data['user']['teacher_id']
          user.token = data['token']
          user.save()
          router.replace({name: 'Home'})
        }, e => {
          if (e == 'mismatch') alert('密码错误，请重新输入')
          this.isLoading = false
        })
      }
    },
  },
}
</script>

<style scoped>
.main {
  min-height: 100vh;
  background-image: url("../assets/login-bkg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.header {
  margin-top: 75px;
  height: 32px;
}

.form {
  box-sizing: border-box;
  margin-top: 26px;
  padding-top: 20px;
  background-image: url("../assets/login-form-bkg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 358px;
}

.form-wrapper {
  width: 240px;
  margin: auto;
}

.form-header {
  font-family: Kaiti;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.form-header::before {
  content: "";
  margin-right: 4px;
  margin-top: 2px;
  width: 3px;
  height: 13px;
  background: #D64F54;
  border-radius: 1px;
}

.form-title {
  margin-top: 15px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #333333;
}

.form-input {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 2px;
}

.form-icon {
  width: 34px;
  height: 34px;
}

.input-divider {
  width: 1px;
  height: 12px;
  background: #D0D0D0;
  margin-right: 7px;
}

input {
  border: none;
  color: #333333;
  flex: 1;
  margin-right: 10px;
  height: 34px;
}

.remember-password {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  color: #999999;
  cursor: pointer;
  user-select: none;
}

.login-button {
  margin: 34px auto 0;
  background-image: url("../assets/login-button-bkg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 245px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}

.footer-note {
  font-size: 11px;
  font-weight: 400;
  color: #999999;
  text-decoration: none;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
</style>