import {remote} from "@/utils/remote";
import user from "@/domain/User";

export const typicalRequest = (route, addition = {}, method = 'POST') => {
  return new Promise((resolve, reject) => {
    remote[method.toLowerCase()](route, {
      ...addition.data
    }).then(res => {
      console.log(res)
      if (res.data.flag) resolve(res.data.data)
      else reject()
    })
  })
}

export const typicalUserRequest = (route, addition = {}) => {
  return typicalRequest(route, {
    data: {
      login_uid: user.id,
      ...addition.data
    }
  })
}