<template>
  <div class="home">
    <navigator/>
    <div :class="'body ' + (isTeacher ? 'teacher' : '')">
      <div class="menu">
        <loader-stroke v-if="!isLoadedBookList" :margin-top="20" :size="45"/>
        <div v-if="isLoadedBookList">
          <div v-for="(book,index) in books" :key="index"
               :class="'menu-item '+(currentBookIndex == index ? 'active' : '')"
               @click="onClickMenu(index)">
            {{ book['bookName'] }}
            <img v-if="!isTeacher && book.activated" class="activated-icon" src="../assets/icon-menu-activated.png"
                 width="40">
          </div>
        </div>
      </div>
      <div class="content">
        <div class="box">
          <img class="catalog-header" src="../assets/catalog-header-title.png">
          <loader-stroke v-if="!isLoadedCatalog"/>
          <catalog v-if="isLoadedCatalog" :catalog="catalog" :lessons="lessons"
                   @onSelectLesson="onSelectLesson"/>

          <img class="catalog-header" src="../assets/catalog-header-video.png">
          <loader-stroke v-if="!isLoadedLesson"/>
          <div v-if="isLoadedBookList">
            <div v-if="books[currentBookIndex].activated">
              <div v-if="isLoadedLesson">
                <div class="lesson-title">{{ currentLesson['lessonName'] }}</div>
                <div class="words">
                  <div v-for="(word,index) in words" :key="index">
                    <div class="word-block" @click="onClickWord(words[index]['wid'])">
                      <img class="word-cover" :src="word.cover"/>
                      <div class="word-name">{{ word['wordName'] }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="books[currentBookIndex].postponed" class="course-note">
              <div v-if="(currentBookIndex + 1) % 2 == 0">
                次年2月1日开放
              </div>
              <div v-else>
                次年9月1日开放
              </div>
            </div>
            <div v-else>
              <img src="../assets/lesson-empty-default.png" width="225" style="margin-top: 50px">
            </div>
          </div>

          <div v-if="isTeacher">
            <img class="catalog-header" src="../assets/catalog-header-pdf.png">
            <loader-stroke v-if="!isLoadedLesson"/>
            <div v-if="isLoadedLesson">
              <img class="pdf-cover" src="../assets/pdf-play-cover.png" width="240" height="135"
                   @click="onClickCourseware"/>
            </div>
          </div>
        </div>
        <!--        <img v-if="isTeacher" class="bottom-bkg" src="../assets/main-bkg-bottom-teacher.png">-->
        <!--        <img v-else class="bottom-bkg" src="../assets/main-bkg-bottom-student.png">-->
      </div>
    </div>

    <!--    <img alt="Vue logo" src="../assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import {SERVER_URL} from "@/utils/remote";
import Catalog from "@/components/Catalog";
import LoaderStroke from "@/components/LoaderStroke";
import router from "@/router";
import user from "@/domain/User";
import Navigator from "@/components/Navigator";
import {typicalRequest, typicalUserRequest} from "@/utils/request";
import {requestLesson} from "@/domain/Lesson";

function requestBookList() {
  return typicalRequest('GetClassLists', {}, 'GET')
}

function requestActivatedBookList() {
  return typicalUserRequest('user/my-class')
}

function requestBookCatalog(id) {
  return typicalRequest('GetClassList', {
    data: {bid: id}
  })
}

export default {
  name: 'Home',
  components: {
    Navigator,
    LoaderStroke,
    Catalog,
    // HelloWorld,
  },
  data() {
    return {
      isLoadedBookList: false,
      isLoadedCatalog: false,
      isLoadedLesson: false,

      isTeacher: user.isTeacher,
      currentBookIndex: 0,
      books: [],
      lessons: [],
      catalog: [],
      currentLessonIndex: 0,
      currentLesson: [],
      words: [],
      coursewareAddress: '',
    }
  },
  created() {
    // console.log(this.$route.query.code)
    Promise.all([requestBookList(), requestActivatedBookList()])
        .then(data => {
          const activatedIds = !this.isTeacher ? data[1].list.map(item => {
            if (item["days"] > 0) {
              return item["book_id"].split(',').map(id => +id)
            }
          }).flat() : []
          this.books = data[0].map(item => {
            item.activated = user.isTeacher || activatedIds.some(e => item.bid <= e)//.includes(item.bid)
            return item
          })
          const max = activatedIds.reduce((max, e) => e > max ? e : max, -1)
          if (max >= 0 && max < 12) {
            this.books[max].postponed = true
          }
          this.currentBookIndex = 0
          this.isLoadedBookList = true
          if (this.books.length) {
            this.loadBook(this.books[this.currentBookIndex].bid)
          } else {
            this.isLoadedCatalog = true
            this.isLoadedLesson = true
          }
        })
  },
  methods: {
    loadBook(id) {
      return requestBookCatalog(id).then(data => {
        let treeTitle = ""
        const list = []
        data.map((item, index) => {
          // item.inPreview = item["isFree"] == 1
          if (treeTitle != item["property"]) {
            treeTitle = item["property"]
            list.push({
              title: treeTitle,
              type: 'branch',
            })
          }
          const matched = item["lessonName"].match(/\d+/)
          item.title = matched ? matched[0] : item["lessonName"]
          list.push(item)
          return item
        })
        this.catalog = list
        this.lessons = data
        this.isLoadedCatalog = true
        this.onSelectLesson(0)
      })
    },
    onClickMenu(index) {
      this.currentBookIndex = index
      this.loadBook(this.books[this.currentBookIndex].bid)
    },
    onSelectLesson(index) {
      this.currentLessonIndex = index
      this.currentLesson = this.lessons[index]
      const id = this.currentLesson['lid']

      if (this.books[this.currentBookIndex].activated) this.loadLesson(id)
      else this.isLoadedLesson = true
    },
    loadLesson(id) {
      requestLesson(id).then(data => {
        this.coursewareAddress = data.courseware
        this.words = data.words
      }, e => {
        alert('课程接口错误 ' + e)
      }).finally(() => {
        this.isLoadedLesson = true
      })
    },
    onClickWord(wordId) {
      router.push({
        name: 'Play',
        query: {
          type: 'video',
          lessonId: this.currentLesson.lid,
          lessonName: this.currentLesson.lessonName,
          wordId,
        },
      })
    },
    onClickCourseware() {
      router.push({
        name: 'Play',
        query: {
          type: 'pdf',
          address: this.coursewareAddress,
        },
      })
    },
  },
}
</script>

<style scoped src="../style/home.css"/>
<style scoped>
</style>